<!--
 * @Description: 房间显示
 * @Date: 2022-03-16 17:40:28
 * @LastEditTime: 2022-03-29 16:13:06
-->
<template>
  <div class="rtc-container">
    <div class="video-row" :class="!$isPad?'video-row2':''">
      <div class="videos" :class="!$isPad?'videos2':''">
        <div class="left">
          <!-- 老师分享视频 -->
          <div class="remote-container teacher-screen" v-if="teacherScreen">
            <div
              :key="(teacherScreen.getUserId()+'screen')"
              :id="(teacherScreen.getUserId()+'screen')"
              class="remote-stream-container">
            </div>
          </div>
          <!-- 老师主视频 -->
          <div class="remote-container teacher-video" :class="teacherScreen?'teacher-video-pos':''" v-if="teacherVideo">
            <div
              :key="teacherVideo.getUserId()+'live'"
              :id="teacherVideo.getUserId()+'live'"
              class="remote-stream-container">
            </div>
          </div>
          <div >
            <div v-if="localStream&&(teacherScreen||teacherVideo)" class="local-stream-container local-stream-container3" :class="!$isPad?'local-stream-container2':''">
              <!-- 本地流播放区域 -->
              <div id="localStream" class="local-stream-content"></div>
              <!-- <img src="../assets/image/new/bgclass.png" alt="" class="bg"> -->
            </div>
            <div v-else class="local-stream-container " :class="!$isPad?'local-stream-container2':''">
              <!-- 本地流播放区域 -->
              <div id="localStream" class="local-stream-content"></div>
              <!-- <img src="../assets/image/new/bgclass.png" alt="" class="bg"> -->
            </div>
          </div>
        </div>
      </div>

      <div class="operate">
          <div class="quit" @click="onBack()">退出</div>
          <div class="btn" >
            <img src="../assets/image/new/microphone-1.png" alt="" v-if="!isMutedAudio" @click="muteAudio">
            <img src="../assets/image/new/microphone-2.png" alt="" v-else @click="unmuteAudio">
          </div>
          <div class="btn">
            <img src="../assets/image/new/camera-1.png" alt=""  v-if="!isMutedVideo" @click="muteVideo">
            <img src="../assets/image/new/camera-2.png" alt="" v-else @click="unmuteVideo">
          </div>
        </div>
    </div>


  </div>
</template>

<script>
import rtc from './mixins/rtc.js';
import shareRtc from  './mixins/share-rtc.js';
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
// import  thumbsUpAni  from './mixins/canvas.js';
// eslint-disable-next-line no-debugger


export default {
  name: 'compRoom',
  mixins: [rtc, shareRtc],
  props: {
    room_info: Object,
    teacher_id: Number,
    type: String,
    sdkAppId: Number,
    secretKey: String,
    userId: String,
    roomId: Number,
    cameraId: String,
    microphoneId: String,
    inviteUserSig: String,
  },
  data() {
    return {
      logList: [],
      inviteLink: '',
      showCopiedTip: false,
      isPad: true,
      courseInfo: '',
      praiseBubble: null,
      timer: null,
      scale: false,
      time_id: this.$store.state.time_id,
    };
  },
  computed: {
    isHostMode() {
      return this.type !== 'invite';
    },
    isEnLang() {
      return this.$i18n.locale === 'en';
    },
    showInviteLink() {
      return this.isHostMode && (this.isJoined || this.isShareJoined) && this.inviteLink;
    },
    getWsMsg() {
      return this.$store.state.webSocketMsg;
    },
  },
  watch: {
    cameraId(val) {
      this.switchDevice('video', val);
    },
    microphoneId(val) {
      this.switchDevice('audio', val);
    },
    getWsMsg: {
      handler(newVal) {
        console.log(newVal);
        const newMsg = JSON.parse(newVal.ws);
        switch (newMsg.type) {
          case 'stopAudio':
            // eslint-disable-next-line no-debugger
            // debugger;
            if (newMsg.id.indexOf(this.userId) >= 0) {
              this.$socketApi.webSocketSend({ ctype: 'control', type: 'notice-audio', state: newMsg.status === '1', uid: this.userId });
              if (newMsg.status === '1') {
                this.muteAudio();
              } else {
                this.unmuteAudio();
              }
            }

            break;
          case 'course_status':
            this.$dialog.alert({
              title: '温馨提示',
              message: '当前课程已结束，即将退出',
            }).then(() => {
              // on close
              this.$router.go(-1);
            });
            break;

          default:
            break;
        }
        console.log(newMsg);
      },
    },
  },
  created() {
    this.courseInfo = JSON.parse(localStorage.getItem('courseInfo'));
  },
  mounted() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    this.praiseBubble = document.getElementById('praise_bubble');
    // this.timer = setInterval(() => {
    //   this.addPraise();
    // }, 600);
    // eslint-disable-next-line no-debugger
    // debugger;
    if (width / height > 1.4) {
    //   alert('手机');
      this.isPad = false;
      this.$store.commit('updateEquipment', false);
    } else {
      this.isPad = true;
      this.$store.commit('updateEquipment', true);
    //   alert('ipad');
    }
    this.handleJoinRoom();
  },
  destroyed() {
    this.leave();
    // clearInterval(this.timer);
    // if (!this.$store.state.is_super) {
    //   this.$socketApi.webSocketClose();
    // }
    // sessionStorage.removeItem('token');
  },
  methods: {
    onBack() {
      this.$dialog.alert({
        message: '是否确认退出',
        showCancelButton: true,
      }).then(() => {
        this.$router.go(-1);
        this.leave();
      })
        .catch(() => {
        // on cancel
        });
    },
    handleValueChange(value, key) {
      this[key] = value;
    },
    // onBack() {
    //   this.$router.go(-1);
    //   this.leave();
    // },
    generateInviteLink() {
      if (!this.isHostMode) {
        return;
      }
      const { sdkAppId, secretKey, roomId } = this;
      const inviteUserId = `user_${parseInt(Math.random() * 100000000, 10)}`;
      const userSigGenerator = new LibGenerateTestUserSig(sdkAppId, secretKey, 604800);
      const inviteUserSig = userSigGenerator.genTestUserSig(inviteUserId);
      this.inviteLink = encodeURI(`${location.origin}${location.pathname}#/invite?sdkAppId=${sdkAppId}&userSig=${inviteUserSig}&roomId=${roomId}&userId=${inviteUserId}`);
    },
    handleCopyInviteLink() {
      navigator.clipboard.writeText(this.inviteLink);
      this.showCopiedTip = true;
      setTimeout(() => {
        this.showCopiedTip = false;
      }, 1500);
      this.generateInviteLink();
    },
    // 点击【Join Room】按钮
    async handleJoinRoom() {
      if (this.isHostMode) {
        if (!this.sdkAppId || !this.secretKey) {
          alert(this.$t('Please enter sdkAppId and secretKey'));
          return;
        }
        if (!this.userId || !this.roomId) {
          alert(this.$t('Please enter userId and roomId'));
          return;
        }
        const userSigGenerator = new LibGenerateTestUserSig(this.sdkAppId, this.secretKey, 604800);
        this.userSig = userSigGenerator.genTestUserSig(this.userId);
      } else {
        if (!this.sdkAppId || !this.inviteUserSig || !this.userId || !this.roomId) {
          alert(this.$t('Please reacquire the invitation link'));
          return;
        }
        this.userSig = this.inviteUserSig;
      }
      await this.initClient();
      await this.join();
      await this.initLocalStream();
      await this.playLocalStream();
      await this.publish();
      this.generateInviteLink();
    },

    // 点击【Publish】按钮
    async handlePublish() {
      await this.publish();
    },

    // 点击【Unpublish】按钮
    async handleUnpublish() {
      await this.unPublish();
    },

    // 点击【Leave Room】按钮
    async handleLeave() {
      await this.leave();
    },

    // 点击【开始屏幕分享】按钮
    async handleStartScreenShare() {
      if (!this.sdkAppId || !this.secretKey) {
        alert(this.$t('Please enter sdkAppId and secretKey'));
        return;
      }
      await this.initShareClient();
      await this.initShareLocalStream();
      await this.handleShareJoin();
      await this.handleSharePublish();
      this.generateInviteLink();
    },

    // 点击【停止屏幕分享按钮】
    async handleStopScreenShare() {
      await this.handleShareUnpublish();
      await this.handleShareLeave();
    },

    // 显示成功的 Log
    addSuccessLog() {
      if (!this.isHostMode) {
        return;
      }
      // this.logList.push({
      //   type: 'success',
      //   log,
      // });
      // const { scrollHeight } = this.$refs.logContainer;
      // this.$refs.logContainer.scrollTop = scrollHeight;
    },

    // 显示失败的 Log
    addFailedLog() {
      if (!this.isHostMode) {
        return;
      }
      // this.logList.push({
      //   type: 'failed',
      //   log,
      // });
      // const { scrollHeight } = this.$refs.logContainer;
      // this.$refs.logContainer.scrollTop = scrollHeight;
    },
    reportSuccessEvent(name) {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-success`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      });
    },
    reportFailedEvent(name, error, type = 'rtc') {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-failed#${this.roomId}*${type === 'share' ? this.shareUserId : this.userId}*${error.message}`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      });
    },
  },
};
</script>

<style lang="scss" >
#app{
  padding-bottom: 0;
}
.quit{
  background-image: linear-gradient(to right, #fe689e 0%, #ff5290 100%);
  font-size:0.7rem;
  color: #fff;
  width: 4rem;
  line-height: 1.8rem;
  text-align: center;
  border-radius: 0.3rem;
}
.btm{
  position: fixed;
  width: 60vw;
  left: 20vw;
  bottom: 6vh;
}
.class_info{
  padding: 2rem 2vw;
  .flote{
   overflow: hidden;
   margin-bottom: 0.8rem;
  }
  // .flote2{
  //   height: 3rem;
  // }
  .title{
    display: flex;
    align-items: center;
    font-size: 1.7rem;
    background: rgba(255, 255, 255, 0.5);
    padding: 0.4rem 0;
    padding-left: 0.4rem;
    padding-right: 1.3rem;
    border-radius: 2rem;
    color: #fa6c7c;
    img{
      width: 3rem;
      // margin-bottom: 0.5rem;
      margin-right: 0.5rem;
    }
    float: left;
  }
  .name{
    color: #666;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    padding: 0.4rem 0;
    padding-left: 0.4rem;
    padding-right: 1.3rem;
    border-radius: 2rem;
    float: left;

    img{
      width: 2rem;
      margin-right: 0.5rem;
      //  margin-bottom: 0.5rem;
    }
  }
  .date{
     color: #666;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    padding: 0.4rem 0;
    padding-left: 0.4rem;
    padding-right: 1.3rem;
    border-radius: 2rem;
    float: left;
    img{
      width: 2rem;
      margin-right: 0.5rem;
    }
  }
}
.rtc-container {
  .label {
    margin: 14px 0 6px;
    text-align: left;
    font-weight: bold;
  }
  .video-row{
    display: flex;
    justify-content: space-between;
    .operate{
      height:44.5vw;
      margin-left: 2rem;
      .btn{
        width: 2rem;
        margin: 1rem auto;
        img{
          width: 100%;
        }
      }
    }
  }
  .video-row2{
    .operate{
      height:14rem;
      margin-left: 2rem;
    }
  }


  .invite-link-container {
    width: 100%;
    color: #084298;
    background-color: #cfe2ff;
    position: relative;
    padding: 10px 16px;
    margin-bottom: 16px;
    border: 1px solid #b6d4fe;
    border-radius: 0.25rem;
    .invite-input {
      margin-top: 10px;
    }
    .invite-btn {
      display: flex;
      cursor: pointer;
    }
  }

  .info-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .log-container {
      flex-grow: 1;
      border: 1px solid #dddddd;
      height: 360px;
      padding: 10px;
      margin-right: 16px;
      overflow-y: scroll;
      .log-label {
        margin: 0 0 6px;
        font-weight: bold;
      }
      .log-state {
        display: inline-block;
        margin-right: 6px;
      }
      > div {
        font-size: 12px;
      }
    }
    .local-stream-container {
      width: 480px;
      height: 360px;
      position: fixed;
      top: 500px;
      flex-shrink: 0;
      // img{
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      // }
      .local-stream-content {
        width: 100%;
        height: 100%;
      }
      .local-stream-control {
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px;
        .control {
          margin-left: 10px;
        }
        .icon-class {
          color: #fff;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .info-container-mobile {
    display: block;
    .log-container {
      margin-right: 0;
    }
    .local-stream-container {
      width: 20vh;
      height: 15vh;
      overflow: hidden;
    border-radius: 0.4rem;
    }
  }

  .remote-container {
    width: 100%;
    height: 100%;
    position: absolute;
    // margin-top: 10px;
    // display: flex;
    // flex-wrap: wrap;
    .remote-stream-container {
      // width: 320px;
      // height: 240px;
      width: 100%;
      height: 100%;
      // margin: 0 10px 10px 0;
    }
  }
  .remain-container{
    width: 20vh;
      height: 15vh;
      overflow: hidden;
    border-radius: 0.4rem;
    margin-top: 0.2rem;
  }
}
.local-stream-container{
  position: absolute;
  width: 100;
  z-index: 100;
  width: 78vw;
  height: 44.5vw;
  .local-stream-content{
    width: 78vw;
    height: 44.5vw;
    border-radius: 1rem;
    overflow: hidden;
    .local-stream-content{
      width: 78vw;
      height: 44.5vw;
    }
  }
  // top: -500px;
  // left: -1000px;
}
.local-stream-container2{
  width:24rem;
  height:14rem;
  .local-stream-content{
    width:24rem;
  height:14rem;
    }
}

.local-stream-container3{
  .local-stream-content{
    position: absolute;
    height: 15vh;
    width: 20vh;
    left: 0.7rem;
    bottom: 0.7rem;
    border-radius: 0.4rem;
    overflow: hidden;
  }
}
.local-stream-control{
  display: none;
}
.videos{
  // height: 60vh;
  width:78vw;
  height:44.5vw;
  display: flex;
  justify-content: space-between;

  box-sizing: border-box;
  position: relative;
  .teacher-video{
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .teacher-video-pos{
    position: absolute;
    right: 0.7rem;
    bottom:  0.7rem;
    height: 15vh;
    width: 20vh;
    z-index: 100;
    border-radius: 0.4rem;
    overflow: hidden;
  }
  .left{
    width: 100%;
    height:44.5vw;
    position: relative;
    overflow: hidden;
    border-radius: 3vw;
    background: url('../assets/image/5.png') #fff no-repeat center center;
    background-size: 30vw;
  }
}
.videos2{
  width:24rem;
  height:14rem;
  .left{
    width:24rem;
    height: 14rem;
  }
  .teacher-video-pos{
      position: absolute;
      right: 0.7rem;
      bottom: 0.7rem;
        // height: 22.5vh;
        // width: 30vh;
    }
}


body {
  --bubble_time: 3s;
  --bubble_scale: 0.8s;
}

.praise_bubble {
  width: 100px;
  height: 200px;
  position: absolute;
  right: 0;
  z-index: 100;
  bottom: -2vw;
  // background-color: #f4f4f4;
}


</style>

<i18n>
{
	"en": {
		"Operation": "Operation",
    "Join Room": "Join Room",
    "Publish": "Publish",
    "Unpublish": "Unpublish",
    "Leave Room": "Leave Room",
    "Start Screen Share": "Start Screen Share",
    "Stop Screen Share": "Stop Screen Share",
    "Please enter sdkAppId and secretKey": "Please enter sdkAppId and secretKey",
    "Please enter userId and roomId": "Please enter userId and roomId",
    "Please reacquire the invitation link": "Please reacquire the invitation link!"
	},
	"zh": {
		"Operation": "操作",
    "Join Room": "进入房间",
    "Publish": "发布流",
    "Unpublish": "取消发布流",
    "Leave Room": "离开房间",
    "Start Screen Share": "开始共享屏幕",
    "Stop Screen Share": "停止共享屏幕",
    "Please enter sdkAppId and secretKey": "请输入 sdkAppId 和 secretKey",
    "Please enter userId and roomId": "请输入 userId 和 roomId",
    "Please reacquire the invitation link": "请重新获取邀请链接！"
	}
}
</i18n>
