<!--
 * @Description: quick demo - vue2 版本页面
 * @Date: 2022-03-14 16:56:36
 * @LastEditTime: 2022-03-29 17:01:32
-->
<template>
  <div id="app">
    <div  v-if="$store.state.isLandscape">
        <div class="content" :class="!$isPad?'content2':''">
          <comp-room
            :sdkAppId="Number(sdkAppId)"
            :secretKey="secretKey"
            :userId="userId"
            :roomId="Number(roomId)"
            :cameraId="cameraId"
            :teacher_id="teacher_id"
            :room_info="room_info"
            :microphoneId="microphoneId"></comp-room>
        </div>
        <div class="anim-alone" v-show="isAlone">
          <div class="photo-box">
            <div class="photo anime-flip">
              <img src="../assets/image/new/border.png" alt="" class="border">
              <div class="photo">
                <img :src="photo" alt="" >
              </div>
            </div>
            <div class="uname">{{uname}}</div>
          </div>
        </div>
        <div class="anim-all" v-if="isAward"></div>
    </div>
    <div class="hengping" v-else>
      <div>
        <img src="../assets/image/new/hengping.png" alt="">
        <div class="tishi">请横屏进行观看</div>
      </div>

    </div>
  </div>
</template>

<script>
import compRoom from '@/components/comp-room.vue';
import { clearUrlParam } from '@/utils/utils';
// import rtc from '../components/mixins/rtc.js';
// import shareRtc from  '../components/mixins/share-rtc.js';

export default {
  name: 'App',
  // mixins: [rtc, shareRtc],
  components: {
    compRoom,
  },
  data() {
    return {
      sdkAppId: 1400763800,
      secretKey: 'de03c082b7c746fd6497a1e06366a9a8f828930175663200636a403a8f57b092',
      userId: sessionStorage.getItem('student_id'),
      roomId: sessionStorage.getItem('time_id'),
      cameraId: '',
      teacher_id: Number(sessionStorage.getItem('teacher_id')),
      // Number(this.$store.state.teacher_id),
      microphoneId: '',
      isAward: false,
      isAlone: false,
      room_info: this.$store.state.roomInfo,
      photo: '',
    };
  },
  mounted() {
    // eslint-disable-next-line no-debugger
    // debugger;
    // this.teacher_id = Number(this.$store.state.teacher_id);
    clearUrlParam();
  },
  computed: {
    getWsMsg() {
      return this.$store.state.webSocketMsg;
    },
  },
  watch: {
    getWsMsg: {
      handler(newVal) {
        console.log('-----------------------------');
        console.log(newVal);
        const newMsg = JSON.parse(newVal.ws);
        switch (newMsg.type) {
          case 'award':
            if (this.isAward) return;
            this.isAward = true;
            setTimeout(() => {
              this.isAward = false;
            }, 5000);
            break;
          case 'alone_award':
            if (this.isAlone) return;
            if (newMsg.id.indexOf(this.userId) >= 0) {
              this.isAlone = true;
              this.photo = newMsg.url;
              this.uname = newMsg.name;
              setTimeout(() => {
                this.isAlone = false;
              }, 5000);
            }

            break;
          case 'give_up':
            this.$store.dispatch('updateGiveUp', newMsg.num);
            this.addPraise();
            break;
          case 'course_status':
            this.$dialog.alert({
              title: '温馨提示',
              message: '当前课程已结束，即将退出',
            }).then(() => {
              // on close
              this.$router.go(-1);
            });
            break;

          default:
            break;
        }
        console.log(newMsg);
      },
    },
  },
  methods: {
    onAudio() {
      // eslint-disable-next-line no-debugger
      debugger;
      if (this.isMutedAudio) {
        this.unmuteAudio();
      } else {
        this.muteAudio();
      }
    },
    onBack() {
      this.$dialog.alert({
        message: '是否确认退出',
        showCancelButton: true,
      }).then(() => {
        this.$router.go(-1);
        this.leave();
      })
        .catch(() => {
        // on cancel
        });
    },
    quitNotebook() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.hengping{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.461);
  .tishi{
    font-size: 1.8rem;
    color: #fff;
    margin-top: 1rem;
  }
  img{
    display: block;
    margin: 0 auto;
    width: 20vw;
    animation-name: scaleAnimation; // 动画名
    animation-duration: 2s; // 动画时长
    animation-iteration-count: infinite; // 永久动画
    transition-timing-function: ease-in-out; // 动画过渡

  }
  @keyframes scaleAnimation { // 动画设置
            0% {
                transform:rotate(10deg);
            }

            20% {
                transform: rotate(-10deg);
            }

            60% {
                transform: rotate(10deg);
            }
            80% {
                transform: rotate(-10deg);
            }
            100% {
                transform: rotate(10deg);
            }
        }
}
.anim-all{
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: url(../assets/image/new/award.gif) rgba(0, 0, 0, 0.307) no-repeat center ;
  background-size:auto 100% ;
  z-index: 1000;
}
.anim-alone{
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: url(../assets/image/new/test.gif) rgba(0, 0, 0, 0.307) center center;
  background-size: 100% auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .photo{
    width: 15rem;
    height: 10rem;
    margin-top: -1rem;
    position: relative;
    padding: 1px;
    .photo{
      display: block;
      margin: 0.5rem;
      width: 14rem;
      height: 9rem;
      overflow: hidden;
      border-radius: 0.5rem;
      img{
        width: 100%;
      }
    }
    .border{
      position: absolute;
      z-index: 1;
      width: 15rem;
      height: 10rem;
      top: 0;
      left: 0;
    }
  }
  .uname{
    margin-top: 0.5rem;
    font-family: "cuyuan";
    text-align: center;
    color: #ff5290;
    text-shadow: 0 0 0.2rem #fff;
  }
}
.anime-flip{ animation: flip 1.5s 1 ease-in-out; }

@keyframes flip { //先转个圈圈
	0% {
		-webkit-transform: perspective(1200px) scaleX(1) translateZ(0) rotateY(-1turn);
		transform: perspective(1200px) scaleX(1) translateZ(0) rotateY(-1turn);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
        //再来个圈圈并加点变化

	40% {
		-webkit-transform: perspective(1200px) scaleX(1) translateZ(150px) rotateY(-190deg);
		transform: perspective(1200px) scaleX(1) translateZ(150px) rotateY(-190deg);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	50% {
		-webkit-transform: perspective(1200px) scaleX(1) translateZ(150px) rotateY(-170deg);
		transform: perspective(1200px) scaleX(1) translateZ(150px) rotateY(-170deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	80% {
		-webkit-transform: perspective(1200px) scale3d(0.95, 0.95, 0.95) translateZ(0) rotateY(0deg);
		transform: perspective(1200px) scale3d(0.95, 0.95, 0.95) translateZ(0) rotateY(0deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	to {
		-webkit-transform: perspective(1200px) scaleX(1) translateZ(0) rotateY(0deg);
		transform: perspective(1200px) scaleX(1) translateZ(0) rotateY(0deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
}

.quit{
  background-image: linear-gradient(to right, #fe689e 0%, #ff5290 100%);
  font-size:0.7rem;
  color: #fff;
  width: 4rem;
  line-height: 1.8rem;
  text-align: center;
  border-radius: 0.3rem;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height:100vh;
  padding-bottom: 40px;
  background: url('../assets/image/bg.png');
  background-size: 100% 100%;
  .content {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem ;
    // margin: 0 auto;
    // max-width: 1320px;
    .operate{
      height:44.5vw;
      .btn{
        width: 2rem;
        margin: 1rem auto;
        img{
          width: 100%;
        }
      }
    }
    .alert {
      padding-top: 20px;
      font-size: 16px !important;
    }
    &.content-mobile {
      width: 100%;
      // padding: 0 16px 20px;
    }
    .label {
      margin: 14px 0 6px;
      text-align: left;
      font-weight: bold;
    }
    .param-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      div {
        width: calc((100% - 20px) / 2);
        margin-bottom: 10px;
      }
      div:nth-last-child(2), div:nth-last-child(1) {
        margin-bottom: 0;
      }
      &.param-container-mobile {
        div {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
  .content2{
    padding: 0 2rem ;
    padding-right: 1.5rem;
    width: 33rem;
    // justify-content: center;
    margin: 0 auto;
    .operate{
      height:14rem;
      margin-left: 2rem;
    }
  }
}
</style>

<i18n>
{
	"en": {
		"Params": "Params",
    "Device Select": "Device Select",
    "Alert": "Notes: this Demo is only applicable for debugging. Before official launch, please migrate the UserSig calculation code and key to your backend server to avoid unauthorized traffic use caused by the leakage of encryption key.",
    "Click": "View documents",
    "Url": "https://intl.cloud.tencent.com/document/product/647/35166"
	},
	"zh": {
		"Params": "参数",
    "Device Select": "设备选择",
    "Alert": "注意️：本 Demo 仅用于调试，正式上线前请将 UserSig 计算代码和密钥迁移到您的后台服务器上，以避免加密密钥泄露导致的流量盗用。",
    "Click": "查看文档",
    "Url": "https://cloud.tencent.com/document/product/647/17275"
	}
}
</i18n>

