/*
 * @Description: 全局样式
 * @Date: 2022-03-09 16:42:16
 * @LastEditTime: 2022-03-29 16:36:04
 */
import Vue from 'vue';
import App from './App.vue';

import '@/utils/aegis.js';
import './rem.js';

import '@/assets/style/global.css';
import '@/assets/icons';
import '@/assets/style/theme/index.css';
import store from './store';
import { server } from './utils/http-service';
import { isMobile } from '@/utils/utils';
import socketApi from './utils/socket';
import VueCookies from 'vue-cookies';
import Vant from 'vant';
import 'vant/lib/index.css';
import AppSocket from './components/AppSocket.vue';

// import 'lib-flexible';

// import {
//   Collapse,
//   CollapseItem,
//   Select,
//   Option,
//   Input,
//   Button,
//   Message,
//   MessageBox,
//   Tooltip,
//   Alert,
// } from 'element-ui';

import router from './router';
import i18n from './locales/i18n';

/**
 *  重写ElementUI的Message
 */
const os = (function () {
  const ua = navigator.userAgent;
  const isWindowsPhone = /(?:Windows Phone)/.test(ua);
  const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone;
  const isAndroid = /(?:Android)/.test(ua);
  const isFireFox = /(?:Firefox)/.test(ua);
  const isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox
      && /(?:Tablet)/.test(ua));
  const isPhone = /(?:iPhone)/.test(ua) && !isTablet;
  const isPc = !isPhone && !isAndroid && !isSymbian;
  return {
    isTablet,
    isPhone,
    isAndroid,
    isPc,
  };
}());
console.log('os', os);
if (os.isAndroid || os.isPhone) {
  Vue.prototype.$isPad = false;
} else if (os.isTablet) {
  // 平板
  Vue.prototype.$isPad = true;
} else if (os.isPc) {
  // 电脑
  Vue.prototype.$isPad = true;
}
// if (document.body.clientWidth > document.body.clientHeight) {
//   // alert('横屏');
//   store.commit('updateLandscape', true);
//   // Vue.prototype.$isLandscape = true;
// } else {
//   // alert('竖屏');
//   // Vue.prototype.$isLandscape = false;
//   store.commit('updateLandscape', false);
// }

const evt = 'onorientationchange' in window ? 'orientationchange' : 'resize';
window.addEventListener(evt, resize, false);
function resize() {
  // eslint-disable-next-line no-debugger
  // debugger;
  console.log(document.body.clientWidth);
  setTimeout(() => {
    if (document.body.clientWidth < document.body.clientHeight) {
      console.log(true);
      // alert('竖屏');
      store.commit('updateLandscape', false);
    } else {
      // alert('横屏');
      console.log(false);
      store.commit('updateLandscape', true);
    }
  }, 100);
  // if (document.body.clientWidth < document.body.clientHeight) {
  //   console.log(true);
  //   // alert('竖屏');
  //   store.commit('updateLandscape', true);
  // } else {
  //   // alert('横屏');
  //   console.log(false);
  //   store.commit('updateLandscape', false);
  // }
}
resize(true);
// const evt = 'onorientationchange' in window ? 'orientationchange' : 'resize';
// window.addEventListener(evt, () =>  {
//   // window.offsetWidth;
//   alert(1111);
//   console.log(window.offsetWidth);
//   if (document.body.clientWidth > document.body.clientHeight) {
//     // Vue.prototype.$isLandscape = false;
//     store.commit('updateLandscape', false);
//   } else {
//     // Vue.prototype.$isLandscape = true;
//     store.commit('updateLandscape', true);
//   }
// });
// window.addEventListener('resize', () =>  {
//   // window.offsetWidth;
//   console.log(window.offsetWidth);
//   // eslint-disable-next-line no-debugger
//   debugger;
//   if (document.body.clientWidth > document.body.clientHeight) {
//     // Vue.prototype.$isLandscape = false;
//     store.commit('updateLandscape', false);
//   } else {
//     // Vue.prototype.$isLandscape = true;
//     store.commit('updateLandscape', true);
//   }
// });
Vue.component('app-socket', AppSocket);
Vue.use(VueCookies);
Vue.use(Vant);
// Vue.prototype.$alert = MessageBox.alert;
// Vue.prototype.$message = new DonMessage();
Vue.prototype.$isMobile = isMobile;
Vue.prototype.$server = server;
Vue.prototype.$store = store;

Vue.prototype.$socketApi = socketApi;


Vue.config.productionTip = false;

document.title = i18n.t('title');

new Vue({
  router,
  i18n,
  // store,
  render: h => h(App),
}).$mount('#app');
