<template>
    <div class="socket">
    </div>
  </template>

<script>
export default {
  name: 'AppSocket',
  created() {
    // const token = sessionStorage.getItem('token');
    const timeId = sessionStorage.getItem('time_id');
    // eslint-disable-next-line no-debugger
    // debugger;
    // ws://api.joyballet.cn:9504
    this.$socketApi.initWebSocket(`wss://api.joyballet.cn/online_course/?type=free&time_id=${timeId}`, (e) => {
      if (e !== 'pong') {
        // eslint-disable-next-line no-console
        console.log(e);
        this.$store.commit('SET_WS_MSG', { time: (new Date()).valueOf(), ws: e });
      }
    });
  },
  beforeDestroy() {
    this.$socketApi.webSocketClose();
  },
};
</script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  // @font-face {
  //     font-family: "ZhanKu";
  //     // src: url('../assets/font/ZhanKuKuaiLeTi2016XiuDingBan-2.ttf');
  // }
  // h1{
  //   font-family: "ZhanKu";
  // }
  // h3 {
  //   margin: 40px 0 0;
  //   font-family: "ZhanKu";
  // }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>
